import React,{useEffect} from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';

export default function ServiceItem(props) {
    useEffect(() => {
        AOS.init({
            duration: 3000, // Default animation duration
          });
      }, []);
    return (
        <div data-aos={props.index % 2 === 0 ? "fade-right":"fade-left"}  data-aos-duration="2000">
            <Card>
                <CardActionArea>
                    <CardContent>
                    <Typography variant="h5" color='primary'>
                            {props.title}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {props.short_note}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    );
}