import React,{useEffect} from 'react';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Divider, Grid, Paper } from '@material-ui/core';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';


export default function ValueCard(props) {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Default animation duration
          });
      }, []);
    return (
        <div>
            <Grid container justify="space-around" data-aos="fade-up" data-aos-duration="1000">
                <Grid item xs={12} lg={3}>
                <CardMedia
                    component="img"
                    image={props.image}
                    title="Contemplative Reptile"
                />
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Paper elevation={2}>
                <CardContent data-aos="fade-left" data-aos-duration="1000">
                <Typography gutterBottom variant="h4">
                            {props.title}
                        </Typography>
                        <Divider />
                        <Typography variant="h5" color="textSecondary" component="p">
                            {props.short_note}
                        </Typography>
                </CardContent>
                </Paper>
                </Grid>
            </Grid>
            {/* <Card>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        image={props.image}
                        title="Contemplative Reptile"
                    />
                    <CardContent>
                        <Typography variant="subtitle1" color="textSecondary" component="p">
                            {props.short_note}
                        </Typography>
                        <Divider />
                        <Typography gutterBottom variant="h5" component="h2">
                            {props.title}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card> */}
        </div>
    );
}