import React from 'react';
import {AppBar, Toolbar, Typography, Box} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

export default function PageTitle({title}) {

  return (
    <AppBar position="static" color="inherit">
        <Toolbar>
            <Box>
                <MenuIcon/>
            </Box>
            <Box flexGrow={1} p={2}>
                <Typography variant='h4'>{title}</Typography>
            </Box>
        </Toolbar>
      </AppBar>
  );
}