import React from 'react'
import ObjectiveCard from './ObjectiveCard'
import { Container, Grid } from '@material-ui/core'



export default function Objectives() {
    const objectives = [
        {
            title: "CYGEN collaborates as Distributor for RT-PCR kit in India",
            short_note: "Seegene RT-PCR kit is from South Korea and is ICMR, DCGI & FDA approved, accepted worldwide for its high quality. We are their distributor for India.",
            image: "assets/covid-test.jpg",
            route:"/"
        },
        {
            title: "CYGEN Redefining Healthcare",
            short_note: "The healthcare IT platform with a focus on predictive and preventive healthcare management.",
            image: "assets/rs-1.jpg",
            route:"/about"
        },
        {
            title: "Building a Healthier Tomorrow",
            short_note: "We are a healthcare community focused on improving the quality of life with our innovative healthcare services and solutions.",
            image: "assets/rs-2.jpg",
            route:"/services"
        },
        {
            title: "Innovative Custom Solutions",
            short_note: "Our commitment is excellence. We deliver quality programs specifically customized to your needs and fulfillment of your wellness goals.",
            image: "assets/rs-3.jpg",
            route:"/"
        }
    ]
    return (
        <Container>
            <Grid container style={{display:'flex',justifyContent:'center'}} spacing={2}>
            {
                objectives.map( 
                    (objective, index) => 
                    <Grid item key={index} md={8} lg={8} sm={12} xs={12} >
                        <ObjectiveCard {...objective} index={index}/>
                    </Grid>)
            }
            </Grid>
        </Container>
    )
}
