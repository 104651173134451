import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  linkText: {
    fontWeight: 200,
    fontSize: '1.15rem',
    color: '#0E9AAD'
  },
  linkBar: {
    textDecoration: 'none',
    color: '#0E9AAD',
  },
});

const SideDrawer = ({ open, onClose }) => {
  const { linkText, linkBar } = useStyles()
  return (
    <Drawer anchor="right" open={open} onClose={onClose} PaperProps={{ style: { width: 200, paddingTop: 50 } }}>
      <List>
        <Link to='/' className={linkBar}>
          <ListItem button>
            <ListItemText primary="Home" className={linkText} />
          </ListItem>
        </Link>
        <Link to='/about' className={linkBar}>
          <ListItem button>
            <ListItemText primary="About" className={linkText} />
          </ListItem>
        </Link>
        <Link to='/services' className={linkBar}>
          <ListItem button>
            <ListItemText primary="Services" className={linkText} />
          </ListItem>
        </Link>
        <Link to='/awards' className={linkBar}>
          <ListItem button>
            <ListItemText primary="Awards" className={linkText} />
          </ListItem>
        </Link>
        <Link to='/contact' className={linkBar}>
          <ListItem button>
            <ListItemText primary="Contact" className={linkText} />
          </ListItem>
        </Link>
      </List>
    </Drawer>
  );
};

export default SideDrawer;
