import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import PageTitle from '../common/PageTitleComponent';
import Features from '../services/OurServices'

export default function ServicesPage() {

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <PageTitle title='Services' />
        </Grid>
        <Grid item xs={12}>
        <Features />
        </Grid>
      </Grid>
    </Paper>
  );
}