import React from 'react'
import ValueCard from './value-card'
import { Grid } from '@material-ui/core'


export default function Values() {
    const objectives = [
        {
            title: "Our Journey",
            short_note: "We are living in a fast-paced era where most of us are already suffering from or are very much prone to various lifestyle-related diseases.",
            image: "assets/journey.jpg"
        },
        {
            title: "Our Mission",
            short_note: "Preventing lifestyle-related diseases by providing predictive analysis and helping each individual to fight the disease before it develops.",
            image: "assets/mission.png"
        },
        {
            title: "Our Target",
            short_note: "At CYGEN Healthcare, our main targets are the people with high-stress level and predisposition to lifestyle related diseases.",
            image: "assets/target.jpg"
        },
        {
            title: "Our Values",
            short_note: "Strengthen our bonds with employees & business partners through fair and equitable dealings as well as timely communication.",
            image: "assets/values.png"
        }
    ]
    return (
        <Grid container spacing={2}>
            {
                objectives.map( 
                    (objective, index) => 
                    <Grid item key={index} xs={12}>
                        <ValueCard {...objective}/>
                    </Grid>)
            }
        </Grid>
    )
}
