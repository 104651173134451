import React,{useEffect} from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import PageTitle from '../common/PageTitleComponent';
import ReactCountryFlag from "react-country-flag"
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';

export default function ContactPage() {
    useEffect(() => {
        AOS.init({
            duration: 3000, // Default animation duration
          });
      }, []);
    return (
        <Paper elevation={3} style={{padding:'1rem'}}>
            <PageTitle title='Contact Details' />
            <Grid container spacing={2} justify='space-around' data-aos="zoom-in" data-aos-duration="1000" style={{paddingTop:'1rem'}}>
                {/* <Grid item xs={12} lg={10}><Typography variant='h5'>Office</Typography></Grid> */}
                {/* <ReactCountryFlag countryCode="US"></ReactCountryFlag> */}
                <Grid item xs={12} lg={4} data-aos="zoom-in" data-aos-duration="1000">
                    <Typography gutterBottom variant='h6'>INDIA</Typography>
                    <Typography variant='h6'>D.no.6-3- 597/1/C</Typography>
                    <Typography variant='h6'>3rd Floor, Banjara Avenue</Typography>
                    <Typography variant='h6'>Naveen Nagar, Khairatabad</Typography>
                    <Typography variant='h6'>Hyderabad, Telangana - 500004</Typography>
                    <Grid container alignItems="center">
                        <Grid item xs={1}><PhoneIcon /></Grid>
                        <Grid item xs={9}>
                            <Typography variant='h6'>+91 99864 51575</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4} data-aos="zoom-in" data-aos-duration="1000" style={{paddingTop:'1rem'}}>
                    <Typography variant='h6'>MALAYSIA</Typography>
                    <Typography variant='h6'>No.2A</Typography>
                    <Typography variant='h6'>Lorong 14/54A, Petaling Jaya</Typography>
                    <Typography variant='h6'>46100 Selangor</Typography>
                    <Typography variant='h6'>Malaysia.</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}