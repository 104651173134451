import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import Values from './our-values';
import PageTitle from '../common/PageTitleComponent';

export default function AboutUsPage() {

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <PageTitle title='About Us' />
        </Grid>
        <Grid item>
        <Values></Values>
        </Grid>
      </Grid>
      
      
    </Paper>
  );
}