import React from 'react'
import { Grid } from '@material-ui/core'
import ServiceItem from './ServiceItem'


export default function Features() {
    const features = [
        {
            title: "ELECTRONIC HEALTH RECORDING",
            short_note: "An electronic health record is a digital version of a patient’s paper chart. EHRs are real-time, patient-centered records that make ...",
            image: "assets/journey.jpg"
        },
        {
            title: "SMART HEALTH ASSESSMENT",
            short_note: "A smart system that suggests a persons disease and suggestion to cure. User to get instant guidance on their health issues...",
            image: "assets/mission.png"
        },
        {
            title: "PERSONALISED HEALTHCARE",
            short_note: "Personalised healthcare leadership roles with CYGEN. We're one of the world's leading pharmaceutical companies...",
            image: "assets/target.jpg"
        },
        {
            title: "HEALTH AWARENESS & EDUCATION",
            short_note: "Health  Awarness and Education Center Behind Bubba's Medicine Shop is a classroom style education buliding featuring class of all aspects...",
            image: "assets/journey.jpg"
        },
        {
            title: "DIETARY & EXERCISE TIPS",
            short_note: "Consider the eating and exercise tips. Also known that anytime you try a food or drink for the first time before a workout,you risk an upset...",
            image: "assets/mission.png"
        },
        {
            title: "PREVENTIVE MEASURES",
            short_note: "Preventive Health Measures encompass a vareity of interventions that can be under taken to prevent or delay the occurence of disease or reduce...",
            image: "assets/target.jpg"
        }
        
    ]
    return (
        <Grid container spacing={4} justify='space-around'>
            {
                features.map( 
                    (feature, index) => 
                    <Grid item key={index} xs={12} lg={5}>
                        <ServiceItem {...feature} index={index}/>
                    </Grid>)
            }
        </Grid>
    )
}
