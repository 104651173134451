import React, { useState } from 'react';
import { AppBar, Box, Grid, Toolbar, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SideDrawer from './common/SideDrawer';

const useStyles = makeStyles({
  linkBar: {
    textDecoration: 'none',
    color: '#0E9AAD',
  },
  linkText: {
    fontWeight: 600,
    fontSize: '1.15rem'
  }
});

export default function Header() {
  const { linkBar, linkText } = useStyles()
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleWindowResize = () => {
    setIsMobileView(window.innerWidth <= 768);
  };
  console.log(isMobileView, 'isMobileView')
  console.log(drawerOpen, 'drawerOpen')
  // Add a window resize event listener to detect screen size changes
  window.addEventListener('resize', handleWindowResize);
  return (
    <div>
      <AppBar position="static" color="inherit" style={{ padding: '1rem' }}>
        <Toolbar>

          <Box flexGrow={1}>
            <a href='/'>
              <img src="brand_logo.gif" alt="Brand Logo Missing" />
            </a>
          </Box>
          <Grid xs={7}></Grid>

          {isMobileView ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon style={{ color: '#0E9AAD', height: '2rem', width: '2rem' }} />
            </IconButton>
          ) : (
            <>
              <Grid item xs={1}>
                <Link to='/' className={linkBar}>
                  <Typography className={linkText}>Home</Typography>
                </Link>
              </Grid>
              <Grid item xs={1} >
                <Link to='/about' className={linkBar}>
                  <Typography className={linkText}>About</Typography>
                </Link>
              </Grid>
              <Grid item xs={1}>
                <Link to='/services' className={linkBar}>
                  <Typography className={linkText}>Service</Typography></Link>
              </Grid>
              <Grid item xs={1}>
                <Link to='/awards' className={linkBar}>
                  <Typography className={linkText}>Awards</Typography></Link>
              </Grid>
              <Grid item xs={1}>
                <Link to='/contact' className={linkBar}>
                  <Typography className={linkText}>Contact us</Typography></Link>
              </Grid>
            </>
          )
          }
          {isMobileView ? (
            <SideDrawer open={drawerOpen} onClose={handleDrawerToggle} />
          ) : (
            <SideDrawer open={false} onClose={() => { }} />
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}