import React,{useEffect} from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';
import {useHistory} from 'react-router-dom'


export default function ObjectiveCard(props) {
  useEffect(() => {
    AOS.init({
        duration: 3000, // Default animation duration
      });
  }, []);
  const history = useHistory()
  const navigateToRoute = (route) => {
    history.push(route)
  }
  return (
    <Card  data-aos={props.index % 2 ===0 ? "fade-right":"fade-left"}  data-aos-duration="1000">
      <CardActionArea>
        <CardMedia
          component="img"
          image={props.image}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" component="p">
            {props.short_note}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={() => navigateToRoute(props.route)}>
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}