import React,{useEffect} from 'react';
import OwlCarousel from 'react-owl-carousel';
import { makeStyles } from '@material-ui/core/styles';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Typography, Card, CardMedia } from '@material-ui/core';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        height: '100%'
    },
    media: {
        paddingTop: '66.25%', // 16:9
    },
    award:{
        paddingTop:'10px'
    }

}));

export default function Awards(props) {
    const classes = useStyles()
    useEffect(() => {
        AOS.init({
            duration: 1000, // Default animation duration
          });
      }, []);
   
    const awards = [
        {
            id: 1, description:"Elecrama startup imapact award on 2018 ", src: "./elacma_award.jpeg"
        },
        {
            id: 2, description:"Digital thought of the year on 6th November 2019", src: "./digital_thought_of_the_year.jpeg"
        },
        {
            id: 3, description:"Certificate of appriciation from Clinica Manila", src: "./global_digital_health_company_of_the_year.jpeg"
        },
        {
            id: 4, description:"Leverging technology for early disease", src: "./leverging_technology_for_earl_disease.jpeg"
        }
    ]

    return (
        <OwlCarousel
            loop={true}
            margin={25}
            autoplay={true}
            autoplayTimeout={3000}
            autoplayHoverPause={true}
            responsiveClass={true}
            stagePadding= {26}
            responsive={{
                0: {
                    items: 1,
                    loop: true
                },
                600: {
                    items: 3
                }
            }}
            URLhashListener={true}
            startPosition= 'URLHash'
            center={true}
            items={3}
            animateOut='slideOutDown'
            animateIn='flipInX'
        >
            {
                awards.map(award =>
                    <Card className={classes.root} key={award.id} data-aos="zoom-in" data-aos-duration="1000">
                        <CardMedia
                            className={classes.media}
                            image={require(`${award.src}`)}
                            title="Award"
                        />
                        <div className={classes.award}>
                            <Typography variant="subtitle2" color="inherit" style={{textTransform: 'uppercase', display:'flex',justifyContent:'center' , width:'100%',padding:'3px 0 3px 0'}} component="p">
                               {award.description}
                            </Typography>
                        </div>
                    </Card>
                )
            }
        </OwlCarousel>
    )
}