import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './header';
import Objectives from './themes/Objectives'
import { Grid, Box } from '@material-ui/core';
import AboutUsPage from './about-us/about-us-page';
import { BrowserRouter, Switch, Route, } from 'react-router-dom';
import ContactPage from './pages/Contact';
import ServicesPage from './pages/SevicesPage';
import Awards from './awards/Awards';


function Website() {
  
  return (
    <Box padding={2} border={1}>
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid container item spacing={2} xs={12}>
          <BrowserRouter>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            <Switch>
              <Route path='/about'>
                <AboutUsPage />
              </Route>
              <Route path='/services'>
                <ServicesPage />
              </Route>
              <Route path='/awards'>
                <Awards />
              </Route>
              <Route path='/contact'>
                <ContactPage />
              </Route>
              <Route exact path='/'>
                <Objectives />
              </Route>
            </Switch>
          </Grid>
          </BrowserRouter>
        </Grid>
        {/* <Grid item xs={12}>
          <Footer></Footer>
        </Grid> */}
      </Grid>
    </Box>
  );
}

export default Website;
